import React from 'react';

const SIZE = 150;
const PRESS_RADIUS = 8;
const DISSIPATION = .2;

function getEmptyRow() {
    const squares = [];
    for (let i = 0; i < SIZE; i++) {
        squares.push(0);
    }
    return squares;
}

function getInitialSquares() {
    const squares: number[][] = [];
    for (let i = 0; i < SIZE; i++) {
        squares.push(getEmptyRow());
    }
    return squares;
}

function dx2(u: number[][]) {
    const d: number[][] = [];
    for (let row = 0; row < u.length; row++) {
        const dRow = [];
        for (let col = 0; col < u[0].length; col++) {
            if (col == 0) {
                dRow.push(u[row][col+1] - 2*u[row][col])
            } else if (col == u[0].length - 1) {
                dRow.push(-2*u[row][col] + u[row][col-1])
            } else {
                dRow.push(u[row][col+1] - 2*u[row][col] + u[row][col-1]);
            }
        }
        d.push(dRow);
    }
    return d;
}

function dy2(u: number[][]) {
    const d: number[][] = [];
    for (let row = 0; row < u.length; row++) {
        const dRow = [];
        for (let col = 0; col < u[0].length; col++) {
            if (row == 0) {
                dRow.push(u[row+1][col] - 2*u[row][col]);
            } else if (row == u.length - 1) {
                dRow.push(-2*u[row][col] + u[row-1][col]);
            } else {
                dRow.push(u[row+1][col] - 2*u[row][col] + u[row-1][col]);
            }
        }
        d.push(dRow);
    }
    return d;
}

function laplace(u: number[][]) {
    const l = dx2(u);
    const d2yVal = dy2(u);
    for (let row = 0; row < u.length; row++) {
        for (let col = 0; col < u[0].length; col++) {
            l[row][col] += d2yVal[row][col];
        }
    }
    return l;
}

function update(u: number[][]) {
    const l = laplace(u);
    for (let row = 0; row < u.length; row++) {
        for (let col = 0; col < u[0].length; col++) {
            u[row][col] += DISSIPATION * l[row][col];
            if (u[row][col] > 255) {
                u[row][col] = 255;
            }
            if (u[row][col] < 0) {
                u[row][col] = 0;
            }
        }
    }
    return u;
}

interface S {
    squares: number[][];
}

export default class Heat extends React.Component<{}, S> {

    state: S = {
        squares: getInitialSquares()
    };

    componentDidMount() {
        setInterval(() => this.advance(), 1);
    }

    render() {
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                {this.state.squares.map((row, rowNum) => this.getRow(row, rowNum))}
            </div>
        );
    }

    getRow(row: number[], rowNum: number) {
        return (
            <div style={{ flex: 1, display: 'flex' }}>
                {row.map((v, colNum) => this.getSquare(v, rowNum, colNum))}
            </div>
        );
    }

    getSquare(v: number, rowNum: number, colNum: number) {
        return (
            <div style={{ flex: 1, backgroundColor: `rgb(${v},0,0)` }}
                onClick={() => this.onPressSquare(rowNum, colNum)} />
        );
    }

    onPressSquare(rowNum: number, colNum: number) {
        for (let y = Math.max(0, rowNum - PRESS_RADIUS); y <= Math.min(rowNum + PRESS_RADIUS, SIZE - 1); y++) {
            for (let x = Math.max(0, colNum - PRESS_RADIUS); x <= Math.min(colNum + PRESS_RADIUS, SIZE - 1); x++) {
                if (Math.pow((x - colNum), 2) + Math.pow((y - rowNum), 2) <= Math.pow(PRESS_RADIUS, 2)) {
                    this.state.squares[y][x] = 255 * Math.random();
                }
            }
        }
    }

    advance() {
        this.state.squares = update(this.state.squares);
        this.setState({ squares: this.state.squares });
    }

}
